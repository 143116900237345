import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { StiDestController } from "./StiDestController";
import { CargoController } from "./CargoController";
import { AccountController } from "./AccountController";
import { BaggingController } from "./BaggingController";
import { RequestDownloadIncomingOutgoing } from "@/domain/entities/IncomingOutgoing";
import { container } from "tsyringe";
import { IncomingOutgoingPresenter } from "../presenters/IncomingOutgoingPresenter";
import { MainAppController } from "./MainAppController";
import {
  CalculateDaysBetween2Dates,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { ErrorMessageEntities } from "@/domain/entities/MainApp";
import { StiDestListRequest } from "@/data/payload/api/StiDestApiRequest";
import { BaggingListRequest } from "@/data/payload/api/BaggingApiRequest";
import { CargoListRequest } from "@/data/payload/api/CargoApiRequest";

export interface IncomingOutgoingState {
  isError: boolean;
  errorCause: string;
}

@Module({ namespaced: true, dynamic: true, store, name: "incoming_outgoing" })
class IncomingOutgoingStore extends VuexModule
  implements IncomingOutgoingState {
  public isError = false;
  public errorCause = "";
  form: any = {
    console: "",
    processName: "",
    processValue: "",
    periodStart: "",
    periodEnd: "",
    partnerId: 0
  };

  @Action
  public fetchStiDestList() {
    StiDestController.getStiDestList(
      new StiDestListRequest({
        page: StiDestController.stiDestList.pagination.page,
        limit: StiDestController.stiDestList.pagination.limit,
        search: StiDestController.filter.search,
        startDate: this.form.periodStart,
        endDate: this.form.periodEnd,
        partnerId: this.form.partnerId
      })
    );
  }

  @Action
  public fetchBaggingList() {
    BaggingController.getBaggingList(
      new BaggingListRequest({
        page: BaggingController.baggingList.pagination.page,
        limit: BaggingController.baggingList.pagination.limit,
        search: BaggingController.filter.search,
        startDate: this.form.periodStart,
        endDate: this.form.periodEnd,
        partnerId: this.form.partnerId
      })
    );
  }

  @Action
  public fetchCargoList() {
    const accountOrigin =
      AccountController.accountData.account_location.city_code;
    CargoController.getCargoList(
      new CargoListRequest({
        page: CargoController.cargoData.pagination.page,
        limit: CargoController.cargoData.pagination.limit,
        search: CargoController.search,
        cargoType: CargoController.advanceFilterData.type.value,
        originCity: CargoController.advanceFilterData.cityOriginCode
          ? CargoController.advanceFilterData.cityOriginCode
          : accountOrigin,
        destinationCity: CargoController.advanceFilterData.cityDestinationCode,
        startDate: this.form.periodStart,
        endDate: this.form.periodEnd,
        destinationAirport: CargoController.advanceFilterData.airport,
        partnerId: this.form.partnerId
      })
    );
  }

  @Action
  public getDownload(params: RequestDownloadIncomingOutgoing) {
    MainAppController.showLoading();

    if (CalculateDaysBetween2Dates(params.endDate, params.startDate) > 30) {
      MainAppController.closeLoading();
      MainAppController.showErrorMessage(
        new ErrorMessageEntities({
          title: "Gagal Download!",
          message: "Tanggal yang dipilih maksimal 30 hari",
          image: "image-modal-failed",
          buttonSuccessText: "Ubah Filter Tanggal"
        })
      );
    } else {
      const presenter = container.resolve(IncomingOutgoingPresenter);
      presenter
        .getDownload(params)
        .then(() => MainAppController.closeLoading())
        .catch(err => {
          MainAppController.closeLoading();
          MainAppController.showErrorMessage(
            parsingErrorResponse(err, "", () => this.getDownload(params))
          );
        });
    }
  }

  @Action
  public resetForm() {
    this.setForm({
      console: "",
      search: "",
      processName: "",
      processValue: "",
      periodStart: "",
      periodEnd: "",
      partnerId: 0
    });
  }

  @Mutation
  public setForm(value: any) {
    this.form = value;
  }
}

export const IncomingOutgoingController = getModule(IncomingOutgoingStore);
