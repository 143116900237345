import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";

export const GTMIncomingOutgoingSessionEvent = (
  eventName: string,
  custom = {}
) => {
  dataLayer(eventName, custom, ["userType", "userId", "username"]);
};

export const GTMIncomingOutgoingEvent = (eventName: string, custom = {}) => {
  dataLayer(eventName, custom, ["timestamp", "userType", "userId", "username"]);
};
