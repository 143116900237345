
import debounce from "lodash/debounce";
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString,
  formatDate,
  formatDateNumber,
  formatDateWithoutTime,
  formatValueNumber
} from "@/app/infrastructures/misc/Utils";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { IncomingOutgoingController } from "../../controllers/IncomingOutgoingController";
import { StiDestData, StiDestManifestData } from "@/domain/entities/StiDest";
import { CargoData } from "@/domain/entities/Cargo";
import { capitalize } from "@vue/shared";
import { CargoController } from "../../controllers/CargoController";
import { AccountController } from "../../controllers/AccountController";
import { INCOMING_OUTGOING } from "@/app/infrastructures/misc/RolePermission";
import { StiDestController } from "../../controllers/StiDestController";
import { Pagination } from "@/domain/entities/Pagination";
import { BaggingController } from "../../controllers/BaggingController";
import { RequestDownloadIncomingOutgoing } from "@/domain/entities/IncomingOutgoing";
import { MainAppController } from "../../controllers/MainAppController";
import PrintBagging from "@/app/ui/views/out-going-shipment/bagging/modules/print.vue";
import PrintCargo from "@/app/ui/views/cargo/modules/print.vue";
import { NGenController } from "../../controllers/NGenController";
import {
  GTMIncomingOutgoingEvent,
  GTMIncomingOutgoingSessionEvent
} from "@/app/infrastructures/misc/gtm-event/custom-events/incoming-outgoing-events";
@Options({
  components: {
    SelectSearchV2,
    PrintBagging,
    PrintCargo
  },
  beforeRouteLeave(_: any, __: any, next: any) {
    GTMIncomingOutgoingSessionEvent("session_incoming/outgoing", {
      openTimestamp: this.openTimestamp,
      closeTimestamp: new Date().getTime()
    });
    next();
  }
})
export default class IncomingOutgoing extends Vue {
  openTimestamp = 0;
  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.resetFilter();
    this.openTimestamp = new Date().getTime();

    // default process is cargo
    CargoController.setFirstPage();
    this.form.processName = "Kargo";
    this.form.processValue = "cargo";
    GTMIncomingOutgoingEvent("choose_filter", {
      typeProcess: "cargo"
    });
  }
  page = new Pagination(1, 10);

  formatValueNumber(number: number) {
    return formatValueNumber(number);
  }

  get isLoading() {
    return (
      StiDestController.isLoading ||
      CargoController.isLoading ||
      BaggingController.isLoading
    );
  }

  resetFilter() {
    this.onResetAdvancedFilterCargoForm();
    IncomingOutgoingController.resetForm();
  }

  get form() {
    return IncomingOutgoingController.form;
  }
  get emptyFilter() {
    return !(
      this.form.processValue && checkRolePermission(INCOMING_OUTGOING.VIEW)
    );
  }

  get placeholderSearch() {
    if (this.form.processValue === "sti-dest") {
      return "Cari Berdasarkan ID Manifest...";
    }
    if (this.form.processValue === "bagging") {
      return "Cari Berdasarkan no. bagging...";
    }
    if (this.form.processValue === "cargo") {
      return "Cari Berdasarkan no. kargo...";
    }
    return "";
  }

  get errorCause() {
    if (this.form.processValue === "sti-dest") {
      return StiDestController.errorCause;
    }
    if (this.form.processValue === "bagging") {
      return BaggingController.errorCause;
    }
    if (this.form.processValue === "cargo") {
      return CargoController.errorCause;
    }
    return "";
  }

  get pagination() {
    if (this.form.processValue === "sti-dest") {
      this.page = StiDestController.stiDestList.pagination;
    }
    if (this.form.processValue === "cargo") {
      this.page = CargoController.cargoData.pagination;
    }
    if (this.form.processValue === "bagging") {
      this.page = BaggingController.baggingList.pagination;
    }
    return this.page;
  }

  get incomingOutGoingData() {
    if (this.form.processValue === "sti-dest") {
      return StiDestController.stiDestList.data;
    }
    if (this.form.processValue === "bagging") {
      return BaggingController.baggingList.data;
    }
    if (this.form.processValue === "cargo") {
      return CargoController.cargoData.data;
    }
    return [];
  }

  fetchIncomingOutgoing() {
    if (!this.emptyFilter) {
      if (this.form.processValue === "sti-dest") {
        IncomingOutgoingController.fetchStiDestList();
      }
      if (this.form.processValue === "cargo") {
        IncomingOutgoingController.fetchCargoList();
      }
      if (this.form.processValue === "bagging") {
        IncomingOutgoingController.fetchBaggingList();
      }
    }
  }

  get columns() {
    if (this.form.processValue === "sti-dest") {
      return this.columnsSTIDest;
    }
    if (this.form.processValue === "bagging") {
      return this.columnsBagging;
    }
    if (this.form.processValue === "cargo") {
      return this.columnsCargo;
    }
    return [];
  }

  get columnsBagging() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: any, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "No. Bag",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.bagCode}</div>`;
        }
      },
      {
        name: "Nama Partner",
        styleHead: "w-50 text-left",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${ellipsisString(
            item.bagPartnerName,
            69
          )}</div>`;
        },
        vtooltip: (item: any) =>
          item.bagPartnerName?.length > 69 ? item.bagPartnerName : ""
      },
      {
        name: "Tipe User",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.userType ||
            "-"}</div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.bagDestinationCityCode} - ${item.bagDestinationCityName}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.bagTotalStt.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${item.bagTotalPiece.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Tanggal Bagging",
        styleHead: "w-50 text-left",
        render: (item: any) => {
          return `<div class="text-black-lp-300">${formatDate(
            item.bagCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500 text-12px">${
              item.bagCreatedName
            }</div>`;
        }
      },
      {
        name: "Total Bag Gross Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(item.bagCustomGrossWeight)} Kg</div>`;
        }
      },
      {
        name: "Total Bag Volume Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(item.bagCustomVolumeWeight)} Kg</div>`;
        }
      },
      {
        name: "Total Bag Chargeable Weight",
        styleHead: "w-64 text-left whitespace-no-wrap",
        render: (item: any) => {
          const chargeableWeight =
            item.bagCustomVolumeWeight > item.bagCustomGrossWeight
              ? item.bagCustomVolumeWeight
              : item.bagCustomGrossWeight;
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(chargeableWeight)} Kg</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-36 text-left",
        styleButton: (item: any) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "printer",
                label: "Print PDF",
                clickFunction: async () => {
                  MainAppController.showLoading();
                  const printManifest = await BaggingController.getDetailBagging(
                    item.bagId
                  );
                  if (printManifest) {
                    const data = BaggingController.baggingDetail;
                    this.refs.printBagging.printManifest(data);
                  }
                  MainAppController.closeLoading();
                }
              },
              {
                icon: "invoice",
                label: "Print Label",
                clickFunction: async () => {
                  MainAppController.showLoading();
                  const printLabel = await BaggingController.getDetailBagging(
                    item.bagId
                  );
                  if (printLabel) {
                    const bagVendorNo = BaggingController.baggingDetail.bagVendorNo;
                    this.refs.printBagging.printLabel(item, bagVendorNo);
                    }
                  MainAppController.closeLoading()
                }
              },
              {
                icon: "download-gray",
                label: "Download Excel",
                clickFunction: () =>
                  BaggingController.downloadBagging(item.bagId)
              }
            ]
          };
        }
      }
    ];
  }

  get columnsCargo() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap align-top",
        render: (_: any, index: any) => {
          const no =
            index + 1 + this.pagination.limit * (this.pagination.page - 1);
          return `<div class="overflow-ellipsis text-left">${no}</div>`;
        }
      },
      {
        name: "No. Kargo",
        styleHead: "w-56 text-left whitespace-no-wrap align-top break-all",
        render: (item: CargoData) => {
          return `<p class="w-full break-all text-left">${ellipsisString(
            item.cargoNumber,
            40
          )}</p>`;
        }
      },
      {
        name: "Kota Asal",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.originCityCode} - ${item.originCityName}</div>`;
        }
      },
      {
        name: "Kota Tujuan",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.destinationCityCode} - ${item.destinationCityName}</div>`;
        }
      },
      {
        name: "Kode Bandara Tujuan",
        styleHead: "w-64 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          const code =
            item.cargoType === "plane"
              ? `${item.airportDestinationCityCode} - ${item.airportDestinationCityName}`
              : "-";
          return `<div class="overflow-ellipsis text-left">${code}</div>`;
        }
      },
      {
        name: "No. Seri Kendaraan",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.vehiclePoliceNumber}</div>`;
        }
      },
      {
        name: "Tipe Kargo",
        styleHead: "w-40 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${capitalize(
            item.cargoType
          )}</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalStt}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-28 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${item.totalPieces}</div>`;
        }
      },
      {
        name: "Total Berat Kotor</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoGrossWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Total Berat Dimensi</br>Kargo Aktual",
        styleHead: "w-40 text-center whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="overflow-ellipsis text-left">${this.formatValueNumber(
            item.totalActualCargoVolumeWeight
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDate(
            item.createdAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.createdBy
          }</div></div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-56 text-left whitespace-no-wrap align-top",
        render: (item: CargoData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.updatedAt
          )}<div class="truncate text-gray-lp-600 w-32">${
            item.updatedBy
          }</div></div>`;
        }
      },
      {
        name: "Action",
        key: "actionable_column",
        styleHead: "w-50 text-left align-top",
        showButton: () => {
          return true;
        }
      }
    ];
  }

  actionGroupOptions = (item: any) => {
    const groupOptions = [
      {
        icon: "printer",
        label: "AWB Cargo Genesis",
        clickFunction: () => {
          this.refs.printCargo.printManifestV2(item.cargoNumber);
          this.cargoNumber = item.cargoNumber;
        }
      },
      {
        icon: "invoice",
        label: "Print Label",
        clickFunction: () => {
          this.refs.printCargo.printData(item.cargoNumber, "label");
          this.cargoNumber = item.cargoNumber;
        }
      },
      {
        icon: "document-download-outline-grey",
        label: "Download Excel",
        clickFunction: () => {
          this.downloadManifest(item.id);
        }
      },
      {
        icon: "printer",
        label: "AWB Lion Cargo",
        clickFunction: () => {
          this.printAWBLionCargo(item.cargoNumber);
        }
      },
      {
        icon: "download-gray",
        label: "Download EPL",
        clickFunction: () => {
          this.downloadEPL(item.id);
        }
      }
    ];
    if (
      item.cargoType === "plane" &&
      (item.originCityCode === "BTH" || item.destinationCityCode === "BTH")
    ) {
      return groupOptions;
    } else if (item.cargoType === "plane") {
      return groupOptions.slice(0, 4);
    }
    return groupOptions.slice(0, 3);
  };

  get columnsSTIDest() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (item: any, index: number) => {
          return `<div class="overflow-ellipsis text-left">${index + 1 + this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: "ID Manifest",
        styleHead: "w-28 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestId}</div>`;
        }
      },
      {
        name: "Kota Kedatangan",
        styleHead: "w-48 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestArrivalCityName}</div>`;
        },
        tooltipHeader: () => {
          return `<div class="text-black-lp-300 font-semibold text-12px">Kota Kedatangan</div><div class='mt-2 text-gray-lp-500 text-12px'>Kota Kedatangan adalah kota dimana<br />barang/STT tiba ketika diproses pada fitur<br />STI-DEST.</div>`;
        }
      },
      {
        name: "Total STT",
        styleHead: "w-16 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestTotalStt.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Total Koli",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiDestTotalPiece.toLocaleString(
            "id"
          )}</div>`;
        }
      },
      {
        name: "Total Gross Weight",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiDestTotalGrossWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Total Volume Weight",
        styleHead: "w-50 text-left whitespace-no-wrap",
        render: (item: StiDestData) => {
          return `<div class="overflow-ellipsis text-left">${convertDecimalWithComma(
            item.stiDestTotalVolumeWeight,
            2
          )} Kg</div>`;
        }
      },
      {
        name: "Tanggal STI-DEST",
        styleHead: "w-50 text-left",
        render: (item: StiDestData) => {
          return `<div class="text-black-lp-300">${formatDateWithoutTime(
            item.stiDestCreatedAt
          )}</div>
            <div class="capitalize text-gray-lp-500">${
              item.stiDestCreatedBy
            }</div>`;
        }
      },
      {
        name: "Action",
        key: "button_column",
        styleHead: "w-50 text-left",
        styleCustom: "padding-action-sti",
        styleButton: (item: any) => {
          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            customClass: "button-option-sti",
            clickFunction: () => null,
            groupOptions: [
              {
                icon: "print-summary",
                label: "Print Summary",
                clickFunction: () => this.printSummary(item.stiDestId)
              },
              {
                icon: "printer",
                label: "Print PDF",
                clickFunction: () => this.printManifestSTIDest(item.stiDestId)
              },
              {
                icon: "download-gray",
                label: "Download Excel",
                clickFunction: () =>
                  StiDestController.downloadStiDest(item.stiDestId)
              }
            ]
          };
        }
      }
    ];
  }

  async printSummary(id: number) {
    const printManifest = await StiDestController.getStiDestManifestDetail({
      id
    });

    if (printManifest) {
      const detail: StiDestManifestData = StiDestController.StiDestManifestData;
      const data: Array<any> = [
        {
          name: "Kota Kedatangan",
          value: `${detail.stiDestCityCode} - ${detail.stiDestCityName}`
        },
        {
          name: "Nama Consolidator",
          value: AccountController.accountData.name
        },
        {
          name: "Tanggal STI-DEST",
          value: dateToDateString(detail.stiDestCreatedAt)
        },
        {
          name: "Total STT",
          value: String(detail.stiDestTotalStt)
        },
        {
          name: "Total Koli",
          value: String(detail.stiDestTotalPieces)
        },
        {
          name: "Total Berat Kotor",
          value: `${convertDecimalWithComma(
            detail.stiDesttotalGrossWeight,
            2
          )} Kg`
        },
        {
          name: "Total Berat Dimensi",
          value: `${convertDecimalWithComma(
            detail.stiDesttotalVolumeWeight,
            2
          )} Kg`
        }
      ];

      const print: any = await import(
        "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
      );
      const logo = await import(
        `@/app/ui/assets/images/logo-${this.roleAccount}.png`
      );
      print.default.methods.printSummary(data, id, logo.default);
    }
  }

  async printManifestSTIDest(id: number) {
    const printManifest = await StiDestController.getStiDestManifestDetail({
      id
    });

    if (printManifest) {
      const detailManifest = StiDestController.StiDestManifestData;
      const print: any = await import(
        "@/app/ui/views/incoming-process/sti-dest/modules/print.vue"
      );
      const logo = await import(
        `@/app/ui/assets/images/logo-${this.roleAccount}.png`
      );
      print.default.methods.printManifest(detailManifest, logo.default);
    }
  }

  errorDownloadEpl = false;
  async downloadEPL(id: number) {
    await CargoController.getDownloadData({ id: id, type: "epl" });
  }

  async downloadManifest(id: number) {
    CargoController.getDownloadData({ id: id, type: "cargo" });
  }

  printAWBLionCargo(AWBNo: number) {
    NGenController.printAWBLionCargo({ AWBNo: AWBNo });
  }

  get isLoadingPrint() {
    return CargoController.isLoadingPrint;
  }

  get isErrorPrint() {
    return CargoController.isErrorPrint;
  }

  closeModalErrorPrintList() {
    CargoController.setErrorPrint(false);
    CargoController.setErrorCause("");
  }

  // Print
  cargoNumber = "";

  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  //   select
  statusSelect = false;
  dataProcess = [
    { name: "Pilih Proses", value: "" },
    { name: "STI Dest", value: "sti-dest" },
    {
      name: "Kargo",
      value: "cargo"
    },
    {
      name: "Bagging",
      value: "bagging"
    }
  ];

  onOpenStatusSelect() {
    this.statusSelect = true;
  }

  onCloseStatusSelect() {
    this.statusSelect = false;
  }

  onSelectStatus(name: string, value: string) {
    this.form.processName = name;
    this.form.processValue = value;
    this.onCloseStatusSelect();
    this.isAdvancedFilterCargoOpened = false;
    StiDestController.setSearch("");
    BaggingController.setSearch("");
    CargoController.setSearch("");
    GTMIncomingOutgoingEvent("choose_filter", {
      typeProcess: this.form.processValue
    });
    const hasValue =
      this.advanceFilterDataCargo.type.name ||
      this.advanceFilterDataCargo.type.value ||
      this.advanceFilterDataCargo.cityOriginCode ||
      this.advanceFilterDataCargo.cityDestinationCode ||
      this.advanceFilterDataCargo.airport;
    if (hasValue) {
      this.onResetAdvancedFilterCargo();
      return;
    }
    this.fetchIncomingOutgoing();
  }

  // advance filter cargo
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  isAdvancedFilterCargoOpened = false;
  onChangeAdvancedFilterCargo = debounce(event => {
    CargoController.setAdvanceFilterData(event);
    CargoController.setFirstPage();
    this.fetchIncomingOutgoing();
  }, 500);
  onOpenedAdvancedFilterCargo(event: any) {
    this.isAdvancedFilterCargoOpened = event;
  }
  onResetAdvancedFilterCargoForm() {
    CargoController.setAdvanceFilterData({
      type: { name: "", value: "" },
      cityOriginCode: "",
      cityDestinationCode: "",
      airport: ""
    });
  }
  onResetAdvancedFilterCargo = debounce(() => {
    this.onResetAdvancedFilterCargoForm();
    CargoController.setFirstPage();
    this.fetchIncomingOutgoing();
  }, 500);
  get advanceFilterDataCargo() {
    return CargoController.advanceFilterData;
  }
  typeList = [
    {
      name: "Pesawat",
      value: "plane"
    },
    {
      name: "Truck",
      value: "truck"
    },
    {
      name: "Kereta",
      value: "train"
    }
  ];

  // detail
  get isDetailAble() {
    if (this.form.processValue === "sti-dest") {
      return checkRolePermission(INCOMING_OUTGOING.STI_DEST_DETAIL);
    }
    if (this.form.processValue === "bagging") {
      return checkRolePermission(INCOMING_OUTGOING.BAGGING_DETAIL);
    }
    if (this.form.processValue === "cargo") {
      return checkRolePermission(INCOMING_OUTGOING.CARGO_DETAIL);
    }
    return false;
  }
  toDetail(item: any) {
    if (this.form.processValue === "sti-dest") {
      this.$router.push(`/incoming-outgoing/sti-dest/detail/${item.stiDestId}`);
    }
    if (this.form.processValue === "bagging") {
      this.$router.push(`/incoming-outgoing/bagging/${item.bagId}`);
    }
    if (this.form.processValue === "cargo") {
      this.$router.push(`/incoming-outgoing/cargo/${item.cargoNumber}`);
    }
  }

  // download
  get isDisableDownload() {
    return (
      !this.form.processValue || !this.form.periodStart || !this.form.periodEnd
    );
  }
  onDownload() {
    IncomingOutgoingController.getDownload(
      new RequestDownloadIncomingOutgoing({
        type: this.form.processValue.toUpperCase(),
        startDate: formatDateNumber(this.form.periodStart),
        endDate: formatDateNumber(this.form.periodEnd),
        cargoType: this.advanceFilterDataCargo.type.value,
        originCity: this.advanceFilterDataCargo.cityOriginCode,
        destinationAirport: this.advanceFilterDataCargo.airport,
        destinationCity: this.advanceFilterDataCargo.cityDestinationCode,
        partnerId: this.form?.console?.id
      })
    );
    GTMIncomingOutgoingEvent("download_file", {
      typeProcess: this.form.processValue
    });
  }

  //Filter Search
  onSearch(value: string) {
    if (this.form.processValue === "sti-dest") {
      StiDestController.setSearch(value);
      StiDestController.setFirstPage();
    }
    if (this.form.processValue === "cargo") {
      CargoController.setSearch(value);
      CargoController.setFirstPage();
      GTMIncomingOutgoingEvent("search_process_kargo", {
        cargoNumber: value
      });
    }
    if (this.form.processValue === "bagging") {
      BaggingController.setSearch(value);
      BaggingController.baggingList.pagination.page = 1;
      GTMIncomingOutgoingEvent("search_process_bagging", {
        baggingNumber: value
      });
    }
    this.fetchIncomingOutgoing();
  }
  get searchValue() {
    if (this.form.processValue === "sti-dest") {
      return StiDestController.filter.search;
    }
    if (this.form.processValue === "cargo") {
      return CargoController.search;
    }
    if (this.form.processValue === "bagging") {
      return BaggingController.filter.search;
    }
    return "";
  }
  clearSearch() {
    if (this.form.processValue === "sti-dest") {
      StiDestController.setSearch("");
      StiDestController.setFirstPage();
    }
    if (this.form.processValue === "cargo") {
      CargoController.setSearch("");
      CargoController.setFirstPage();
    }
    if (this.form.processValue === "bagging") {
      BaggingController.setSearch("");
      BaggingController.baggingList.pagination.page = 1;
    }
    this.fetchIncomingOutgoing();
  }

  // advance filter
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    IncomingOutgoingController.setForm({
      console: event.console,
      processName: this.form.processName,
      processValue: this.form.processValue,
      periodStart: event.startDateRange
        ? new Date(event.startDateRange).toLocaleDateString("fr-CA")
        : "",
      periodEnd: event.endDateRange
        ? new Date(event.endDateRange).toLocaleDateString("fr-CA")
        : "",
      partnerId: event.console?.id
    });
    this.fetchIncomingOutgoing();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    IncomingOutgoingController.resetForm();
    this.fetchIncomingOutgoing();
  }, 500);
  get advancedFilterData() {
    return IncomingOutgoingController.form;
  }
}
