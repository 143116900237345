import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex flex-row items-center gap-x-4" }
const _hoisted_3 = {
  key: 1,
  class: "flex flex-row mt-8"
}
const _hoisted_4 = {
  key: 0,
  class: "w-1/4"
}
const _hoisted_5 = { class: "hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_advanced_filter = _resolveComponent("advanced-filter")!
  const _component_button_fill = _resolveComponent("button-fill")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_Search = _resolveComponent("Search")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_PrintBagging = _resolveComponent("PrintBagging")!
  const _component_PrintCargo = _resolveComponent("PrintCargo")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode("div", {
        class: "fixed right-0 h-full z-20",
        style: 
        `background-color:rgba(0,0,0,0.3); top: 31%; width: calc(100% - ${
          _ctx.isAdvancedFilterCargoOpened && _ctx.isCollapseSidebar ? `60px` : `264px`
        });`
      
      }, null, 4), [
        [_vShow, _ctx.isAdvancedFilterCargoOpened]
      ]),
      _createVNode(_component_Header, { class: "flex flex-row" }, {
        default: _withCtx(() => [
          _createVNode("div", _hoisted_2, [
            _createVNode(_component_Select, {
              placeholder: "Pilih Proses",
              class: "w-350px",
              dataSelect: _ctx.dataProcess,
              onOpenSelect: _ctx.onOpenStatusSelect,
              onCloseSelect: _ctx.onCloseStatusSelect,
              onChooseSelect: _ctx.onSelectStatus,
              value: _ctx.form.processName,
              isOpenMenu: _ctx.statusSelect
            }, null, 8, ["dataSelect", "onOpenSelect", "onCloseSelect", "onChooseSelect", "value", "isOpenMenu"]),
            _createVNode(_component_advanced_filter, {
              "model-value": _ctx.advancedFilterData,
              isDisabled: false,
              isScroll: false,
              isCityOrigin: "",
              isDateRange: "",
              isFetchConsolidator: "",
              customTitlePlaceholderCity: "Kota Konsol",
              titleDateRange: "Tanggal",
              defaultDateRange: "7 hari terakhir",
              onChange: _ctx.onChangeAdvancedFilter,
              onOpened: _ctx.onOpenedAdvancedFilter,
              onReset: _ctx.onResetAdvancedFilter
            }, null, 8, ["model-value", "onChange", "onOpened", "onReset"])
          ]),
          _createVNode("div", null, [
            _createVNode(_component_button_fill, {
              custom: "px-6 py-2 bg-red-lp-200 font-normal",
              title: "Download",
              onClick: _ctx.onDownload,
              isLoading: false,
              isDisabled: _ctx.isDisableDownload,
              iconLeft: "document-download-outline-white"
            }, null, 8, ["onClick", "isDisabled"])
          ])
        ]),
        _: 1
      }),
      (_ctx.emptyFilter)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            customMessage: "Mohon lakukan proses filter terlebih dahulu"
          }))
        : _createCommentVNode("", true),
      (!_ctx.emptyFilter)
        ? (_openBlock(), _createBlock("div", _hoisted_3, [
            _createVNode(_component_Search, {
              onInput: _ctx.onSearch,
              onClear: _ctx.clearSearch,
              value: _ctx.searchValue,
              placeholder: _ctx.placeholderSearch,
              class: "w-1/4"
            }, null, 8, ["onInput", "onClear", "value", "placeholder"]),
            (_ctx.form.processValue === 'cargo')
              ? (_openBlock(), _createBlock("div", _hoisted_4, [
                  _createVNode(_component_AdvancedFilter, {
                    title: "Filter Kargo",
                    "model-value": _ctx.advanceFilterDataCargo,
                    isCityOrigin: "",
                    isCityDestination: "",
                    isType: "",
                    isAirport: "",
                    titleTypeCustom: "Tipe Kargo",
                    customTypeList: _ctx.typeList,
                    isScroll: false,
                    isDisabled: false,
                    onChange: _ctx.onChangeAdvancedFilterCargo,
                    onOpened: _ctx.onOpenedAdvancedFilterCargo,
                    onReset: _ctx.onResetAdvancedFilterCargo
                  }, null, 8, ["model-value", "customTypeList", "onChange", "onOpened", "onReset"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.emptyFilter)
        ? (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            class: "mt-0",
            borderTop: false,
            columns: _ctx.columns,
            data: _ctx.incomingOutGoingData,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            totalColumnFreeze: 1,
            isRightFreeze: true,
            onRequest: _ctx.fetchIncomingOutgoing,
            errorCause: _ctx.errorCause,
            onTryAgain: _ctx.fetchIncomingOutgoing,
            groupAction: _ctx.form.processValue === 'cargo' ? true : false,
            groupOptions: _ctx.actionGroupOptions,
            pinnedSubstractHeight: 
        _ctx.form.processValue === 'sti-dest' ? '280px' : '300px'
      ,
            isDetailAble: _ctx.isDetailAble,
            onClick: _ctx.toDetail
          }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "errorCause", "onTryAgain", "groupAction", "groupOptions", "pinnedSubstractHeight", "isDetailAble", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_5, [
      _createVNode(_component_PrintBagging, { ref: "printBagging" }, null, 512),
      _createVNode(_component_PrintCargo, { ref: "printCargo" }, null, 512)
    ]),
    (_ctx.isErrorPrint && !_ctx.isLoadingPrint)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onCancel: _ctx.closeModalErrorPrintList,
          onTryAgain: () => _ctx.refs.printCargo.printData(_ctx.cargoNumber, 'manifest'),
          modal: ""
        }, null, 8, ["errorType", "onCancel", "onTryAgain"]))
      : _createCommentVNode("", true)
  ], 64))
}