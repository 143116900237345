import { QueryParamsEntities } from "./MainApp";

export class RequestDownloadIncomingOutgoing {
  type = "";
  startDate = "";
  endDate = "";
  originCity = "";
  cargoType = "";
  destinationCity = "";
  destinationAirport = "";
  partnerId = "";
  constructor(fields?: Partial<RequestDownloadIncomingOutgoing>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
